import logo from './logo.svg';
import './App.css';
import Header from './Component/Header';
import Home from './Component/Home';
import Footer from './Component/Footer';
function App() {
  return (
  <>
  <Header  />
  <Home/>
  <Footer/>
  </>
  );
}

export default App;
